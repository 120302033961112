<template>
    <div class="certificate">
        <div class="certificate-wrap">
            <a :href="info.file" target="_blank" class="certificate-image">
                <img loading="lazy" :src="info.pdfPreview ? info.pdfPreview : info.file" alt="" />
                <div class="certificate-image__description" v-if="info.description.length" v-html="info.description">
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped lang="scss">
.certificate {
    &-image {
        display: block;
        width: 100%;
        position: relative;
        text-decoration: none;

        //img {
        //    height: 700px;
        //}

        &__description {
            background: #f2f2f2;
            z-index: 2;
            padding: 27px 17px 27px 40px;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #4f4f4f;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@media screen and (max-width: 1140px) {
    .certificate-image {
        img {
            object-fit: contain;
        }
    }
    .certificate-image__description {
        right: 0;
        width: 100%;
        padding: 15px;
        font-size: 16px;
    }
}
</style>
